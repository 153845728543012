import * as React from 'react'
import { Component } from 'react'
import { RouteComponentProps } from "react-router-dom";
import { withRouter, GLGlobal, GLUtil, InvitationType } from "gl-commonui";
import { Row } from 'antd-min';
import { GSAdminLocale } from '../../../locales/localeid';
import { lazyInject, TYPES, CONSTS } from '../../../util/index';
import { InvitationRequest, IInvitationService } from '../../../service/admin/acceptinvitation/index';

interface InvitationSuccessPageProps extends RouteComponentProps<any> {}
interface InvitationSuccessPageStates {
    role?: number
    reference?: string
}

@withRouter
export class AcceptInvitationSuccessPage extends Component<InvitationSuccessPageProps, InvitationSuccessPageStates> {
    @lazyInject(TYPES.IInvitationService)
    service: IInvitationService
    invitationCode = GLUtil.queryParse().code
    email = GLUtil.queryParse().email
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        GLGlobal.refreshLoginInfo();
        this.GetInvitationInfo().then(data => {
            this.setState({
                reference: data.referenceResourceName,
                role: data.invitationType
            });
        }).catch(resp => {});
    }

    GetInvitationInfo() {
        const params: InvitationRequest = {
            invitationCode: this.invitationCode
        };
        return this.service.getInvitationInfoByCode(params);
    }

    renderText() {
        if (this.state.role == InvitationType.globalHead
            || this.state.role == InvitationType.systemAdmin
            || this.state.role == InvitationType.trainingAdmin) {
            return GLGlobal.intl.formatMessage({id: GSAdminLocale.InvitationSuccessForGH}, { email: this.email, role: CONSTS.InvitationType[this.state.role]});
        } else if(this.state.role == InvitationType.regionAdmin
            || this.state.role == InvitationType.trainer
            || this.state.role == InvitationType.regionTrainer
            || this.state.role == InvitationType.accountManager) {
            return GLGlobal.intl.formatMessage({id: GSAdminLocale.InvitationSuccess}, { email: this.email, role: CONSTS.InvitationType[this.state.role], reference: this.state.reference});
        }
    }

    success() {
        let renderText = this.renderText();
        return <>
            <Row className='invitationsubject'>{GLGlobal.intl.formatMessage({ id: GSAdminLocale.InvitationSuccessTitle })}</Row>
            <Row className='invitationtitle'>{renderText}</Row>
        </>;
    }

    render() {
        return <Row>
            {this.success()}
        </Row>
    }
}
